@import '@angular/material/_theming.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: Circular;
    src: local('Circular Std Bold'), url('assets/fonts/CircularStd-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Circular;
    src: local('Circular Std Book'), url('assets/fonts/CircularStd-Book.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    font-family: Circular Std Medium, Arial, Helvetica, sans-serif;
    -webkit-print-color-adjust: exact !important;
}

@include mat-core();

$primary: (Main:#374151,
    contrast: (Main: #ffffff));

$accent: (Main: #626c7d,
    contrast: (Main: #ffffff));

$warn: (Main: #FF695B,
    contrast: (Main: #ffffff));

$custom-collection: (success : #3DCC79,
    warning: #FFBE4F,
    contrast: (success : #FFFFFF,
        danger: #FFFFFF,
    ));


$primary: mat-palette($primary, Main);
$accent: mat-palette($accent, Main);
$warn: mat-palette($warn, Main);

$custom: mat-palette($custom-collection, custom);

$theme: mat-light-theme($primary, $accent, $warn);
$theme: map_merge($theme, (custom: $custom));

@include angular-material-theme($theme);

//Added secondry colors
.mat-success {
    background-color: mat-color($custom, success);
    color: mat-color($custom, success-contrast);
}

.mat-danger {
    background-color: mat-color($custom, danger);
    color: mat-color($custom, danger-contrast);
}

// Overwritting Buttons borders
.mat-focus-indicator.mat-raised-button.mat-button-base {
    border-radius: 6px;
}

.mat-focus-indicator.mat-stroked-button.mat-button-base {
    border-radius: 6px;
}

.mat-focus-indicator.mat-flat-button.mat-button-base {
    border-radius: 6px;
}

//Overwritting Buttons Border-color
.mat-stroked-button.mat-success {
    border-color: mat-color($custom, success);
}


.mat-stroked-button.mat-danger {
    border-color: mat-color($custom, danger);
}

.mat-stroked-button.mat-primary {
    border-color: mat-color($primary, Main);
}

.mat-stroked-button.mat-accent {
    border-color: mat-color($accent, Main);
}

.mat-stroked-button.mat-warn {
    border-color: mat-color($warn, Main);
}

// Styling of Slide Toggle
// works best without disableRipple
mat-slide-toggle {
    .mat-slide-toggle-bar {
        height: 24px;
        width: 42px;
        border-radius: 999px;
        background: #F4F5F7;
        border: 2px solid #E6E6EB;

        .mat-slide-toggle-thumb-container {
            width: 16px;
            height: 16px;
            top: 2px;
            left: 3px;

            .mat-slide-toggle-thumb {
                height: 16px;
                width: 16px;
                background: white;
                box-shadow: 0px 0px 1px rgba(130, 136, 148, 0.16), 0px 2px 4px rgba(130, 136, 148, 0.16);
            }
        }
    }

}

.mat-slide-toggle {
    display: block !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #3DCC79;
    border: 2px solid #3DCC79;
}

// Styling of checkbox
mat-checkbox {

    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-color: #E6E6EB;
        border-radius: 4px;
    }

    .mat-checkbox-inner-container {
        border-radius: 4px;
    }

    .mat-checkbox-inner-container {
        width: 20px;
        height: 20px;
    }
}

.mat-form-field-outline-start,
.mat-form-field-outline-gap,
.mat-form-field-outline-end {
    background-color: white;
}

.mat-form-field-infix {
    border: none;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none !important;
}

.mat-option {
    height: fit-content !important;
}

//Styling of Mat Menu
.mat-menu-content {
    .mat-menu-item {
        width: 250px;
    }
}

.mat-menu-panel {
    border-radius: 8px !important;
}

//Styling of Radio Button
.mat-radio-checked .mat-radio-inner-circle {
    background-color: white !important;
    height: 16px;
    width: 16px;
    top: 2px;
    left: 2px;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    background-color: #374151;
}

// Styling of Mat Paginator
mat-paginator {
    border: 1px solid #E6E6EB;
}

//Success snackbar background-color
.success-snackbar {
    background-color: #D7EEE3;

    mat-icon {
        color: #3DCC79;
    }
}

.fail-snackbar {
    background-color: #FFE7E5;

    mat-icon {
        color: #FF695B;
    }
}

// Modification to Mat Progress Bar
mat-progress-bar {
    background-color: #E6E6EB;
}

.mat-progress-bar .mat-progress-bar-background {
    display: none;
}ng serve

.mat-expansion-indicator::after {
    border-color: #374151;
}

.error-panel .mat-dialog-container {
    border-radius: 10px;
}

//global spinner class
.spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(117, 120, 123, 0.4);
    border-radius: 0 8px 8px 8px;
    height: calc(100vh);
    position: fixed;
}


//Global table styling
table {
    width: 100%;
  
    .mat-header-row {
      height: 35px;
    }
  
    th {
      font-size: 11px;
      line-height: 12px;
      font-weight: 200;
      padding: 0 15px;
      background: #fbfbfb;
      text-transform: capitalize;
      text-align: start;
    }
  
    td {
      font-weight: 450;
      font-size: 14px;
      background: #ffffff;
      border-bottom: 1px #e6e6eb solid;
      white-space: nowrap;
      padding: 0 15px;
      text-align: start;
      mat-checkbox {
        margin: 10px 2px;
      }
  
      ::ng-deep .mat-checkbox-checked.mat-primary .mat-checkbox-background,
      .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
        background-color: #393535;
      }
      ::ng-deep .mat-checkbox-checked.mat-primary .mat-checkbox-ripple .mat-ripple-element {
        background-color: #393535 !important;
      }
  
      .set-price--button {
        background-color: #393535;
        color: white;
        font-weight: 200;
        border-radius: 6px;
      }
    }
  
    .selected-tr {
      background-color: rgb(211, 208, 208);
  
      td {
        color: black;
      }
    }
  
    .edit {
      text-transform: capitalize;
    }
  
    .dots {
      background-color: Transparent;
      border: none;
      color: #babbc2;
      font-size: 25px;
    }
  }
//Global Label colors
.purple {
    background-color: rgba(31, 27, 132, 0.1);
    color: rgb(173, 68, 159);
}
  
.green {
    background-color: rgba(61, 204, 121, 0.1);
    color: rgba(61, 204, 121, 1);
}
  
.blue {
    background-color: rgba(31, 27, 132, 0.1);
    color: rgb(63, 66, 238);
}
  
.yellow {
    background-color: rgba(255, 190, 79, 0.1);
    color: rgba(255, 190, 79, 1);
}

.red {
    color: rgba(255, 93, 93, 1);
    background-color: rgba(255, 93, 93, 0.1);
}

.gray{
    background-color: rgba(61, 204, 121, 0.1);
    color: #fa00bb ;
  }
.orange{
    background-color: rgba(61, 204, 121, 0.1);
    color: #FFA500 ;
}

.light-brown {
    color: #FB5E07;
    background-color: rgba(193, 142, 89, 0.1);
}

mat-dialog-container{
    border-radius: 16px !important;
}

@media print { 
    body, html {
        padding:0px;
        margin:0px;       
    }
    div{
        break-inside: avoid;
    }
}

@page {
    margin: 5mm 0;
}
  

